import React from 'react';
import './YouTubePage.css';

function YouTubePage() {
  return (
    <div className="youtube-page">
      <h1>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"
          alt="YouTube"
          className="youtube-logo" 
        />
      </h1>
      <div className="video-grid">
        {/* First Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/oKzMwLgLSYE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-너희가 어찌하여 여호와를 시험하느냐 <br /><span className="eng-text">(11/10/2024)</span>
          </p>
        </div>

        {/* Second Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/FipqpI6BoT8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-그를 번제로 드리라 <br /><span className="eng-text">(11/3/2024)</span>
          </p>
        </div>

        {/* Third Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/g6--mYCehCM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-(넷째 상황 시리즈) 요나를 삼키게 하셨으므로 <br /><span className="eng-text">(10/27/2024)</span> 
          </p>
        </div>
      </div>
    </div>
  );
}

export default YouTubePage;
