import React from 'react';
import './AboutPage.css'; // Link to the CSS file

import bible2 from './bible2.jpg'; // Import your church image

function AboutPage() {
  return (
    <div className="about-page">
      {/* Background image container */}
      <div className="header-image-container">
        <img src={bible2} alt="Church background" className="header-image" />
        <div className="header-text">
          <h1>주일예배 설교</h1>
          <p>Sermon</p>
        </div>
      </div>

      {/* Main content below the header */}
      <div className="content-container">
        <div className="worship-details">
          <h2>Worship Service</h2>
          <ul>
            <li><strong>주일 예배:</strong> Sunday Worship Service: <span className="time">11:00 AM</span></li>
            <li><strong>EM (Children & Youth):</strong> <span className="time">11:00 AM</span></li>
            <li><strong>수요묵상:</strong> <span className="time">11:00 AM</span></li>
            <li><strong>목요 성경공부:</strong> <span className="time">11:00 AM</span></li>
          </ul>
        </div>

        {/* Section for the video and date */}
        <div className="video-section">
          <p className="sermon-date">주일 예배: November 10, 2024</p> {/* Date Added Here */}
          <iframe
            width="100%"
            height="500px"
            src="https://www.youtube.com/embed/oKzMwLgLSYE"
            title="YouTube sermon video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
